import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const Navbar = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }

    

  `)
    return (
        <div className="grid grid-flow-row grid-cols-3 gap-0 mb-5 gridNavbar">
            
                <div className="navbarLink">
                    <Link to="/">
                        Home
                    </Link>
                    <Link to="/about">
                        O nas
                    </Link>
                    <Link to="/trust">
                        Działaność społeczna
                    </Link>
                </div>
            
            <div className="text-center hidden sm:block">
                <Link to="/" className="sm:w-24 w-1/6" >
                    <StaticImage
                        className="sm:w-12 w-full"
                        alt={data.site.siteMetadata.title}
                        src="../images/logo.svg" />
                </Link>
            </div>
           
                <div className="navbarLink">
                    <Link className="hover:text-primary" to="/children">
                        Dla dzieci
                    </Link>
                    <Link to="/pets">
                        Dla zwierząt
                    </Link>
                    <Link to="/contact">
                        Kontakt
                    </Link>
                </div>
            

            <div className="sm:hidden hidden w-5/6 pl-5">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
               
                <div className="bg-white bg-opacity-95 fixed inset-0 z-10 grid grid-cols-1 gap-4 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
</svg>
     
                    <Link className="monbileNavbarLink" to="/">
                        Home
                    </Link>
                    <Link className="monbileNavbarLink" to="/about">
                        O Nas
                    </Link>
                    <Link className="monbileNavbarLink" to="/trust">
                        Działaność społeczna
                    </Link>
                    <Link className="monbileNavbarLink" to="/children">
                        Dla dzieci
                    </Link>
                    <Link className="monbileNavbarLink" to="/pets">
                        Dla zwierząt
                    </Link>
                    <Link className="monbileNavbarLink" to="/contact">
                        Kontakt
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Navbar