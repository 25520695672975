import * as React from 'react'
import Separator from '../components/separator'
import { useStaticQuery, graphql } from 'gatsby'

const Footer = () => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
        description
        adress
        phone
        mail
        city
      }
    }
  }
`)

  return (

    <div className=" 2xl:w-2/3 lg:w-full w-full md:mx-auto px-10">
      <p className='text-center text-xs'>
        <Separator></Separator>
        <div className="grid grid-flow-row md:grid-cols-3 grid-cols-1 gap-4">
          <div className="text-center">
            <p>{data.site.siteMetadata.adress}</p>
            {data.site.siteMetadata.city}
          </div>
          <div>&nbsp;</div>
          <div className="text-center">
            <p>tel.: {data.site.siteMetadata.phone}</p>
            {data.site.siteMetadata.mail}
          </div>
        </div>
        &copy; 2024 Szymon Kaczmarek & {data.site.siteMetadata.title}
      </p>
    </div>

  )
}

export default Footer