import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Separator = () => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`)

  return (
    <div className="text-center mb-10 relative m-auto">
      <span className="border-t border-gray-300 top-1/2 left-0 absolute md:w-1/3 w-1/12"></span>
      <p className=" font-medium uppercase text-xl tracking-logotagname py-2">
        {data.site.siteMetadata.title}
      </p>
      <p className=" font-normal  uppercase text-xs tracking-logotagline">
        {data.site.siteMetadata.description}
      </p>
      <span className="border-t border-gray-300 top-1/2 right-0 absolute md:w-1/3 w-1/12 "></span>
    </div>
  )
}

export default Separator