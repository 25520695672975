import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Separator from '../components/separator'

import { Helmet } from "react-helmet"

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          adress
          siteUrl
        }
      }
    }
  `)
  return (

    <main className="w-full text-sm">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content={data.site.siteMetadata.author} />
        <title>
          {pageTitle} | {data.site.siteMetadata.title} | {data.site.siteMetadata.description}
        </title>
        <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
        <script>
          console.log('Custom JS here');
        </script>
      </Helmet>
      <title>{pageTitle} | {data.site.siteMetadata.title}</title>
      <div className="bg-secendary font-normal py-2 text-xs text-center">
        <p>Nasza wizja polega na tym aby tworzyć projekty które uczynią świat lepszym miejscem.</p>
      </div>

      <div className=" 2xl:w-2/3 lg:w-full w-full  bg-white md:mx-auto md:mb-5 my-0 md:p-10 p-5  rounded-lg">
        <Navbar></Navbar>
        <Separator></Separator>
        <div className="pb-10">
          {children}
        </div>
      </div>
      <Footer></Footer>
    </main>
  )
}

export default Layout
